import { FETCH_ESS_CMS_WEB_PUSH_FEEDS_PENDING, FETCH_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED, FETCH_ESS_CMS_WEB_PUSH_FEEDS_REJECTED } from '../../actions/esserver/web-push/feeds'

const initialState = {
  feedsById: {
    // test: {
    //     feed,
    // }
  },
  feedsByClientId: {
    //  test: feed
  },
  isLoading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ESS_CMS_WEB_PUSH_FEEDS_PENDING: {
      const feedsByClientId = Object.assign({}, state.feedsByClientId, {
        [action.meta.clientId]: { error: null, feeds: null, isLoading: true }
      })

      return Object.assign({}, state, {
        isLoading: true,
        feedsByClientId
      })
    }

    case FETCH_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED: {
      const feedsByClientId = Object.assign({}, state.feedsByClientId, {
        [action.meta.clientId]: { feeds: action.payload, isLoading: false, error: false }
      })

      const feedsById = Object.assign({}, state.feedsById, ...action.payload.map(feed => ({
        [feed.id]: feed
      })))

      return Object.assign({}, state, { feedsById, feedsByClientId, isLoading: false, error: null })
    }

    case FETCH_ESS_CMS_WEB_PUSH_FEEDS_REJECTED: {
      const feedsByClientId = Object.assign({}, state.feedsByClientId, {
        [action.meta.clientId]: { isLoading: false, error: action.payload }
      })

      return Object.assign({}, state, {
        error: action.payload,
        isLoading: false,
        feedsByClientId
      })
    }

    default: {
      return state
    }
  }
}

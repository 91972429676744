
const VERSION = 22
const TAG = 'INDEXEDDB_WRAPPER'
const APP_STORE = 'cms-client-app_v3'
const CACHE_STORE = 'cms-client-cache_v2'
const DATABASE_NAME = window.location.origin

const stores = [APP_STORE, CACHE_STORE]

const request = (window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB)
  .open(DATABASE_NAME, VERSION)

const db = new Promise((resolve, reject) => {
  request.onsuccess = e => {
    console.log(TAG, 'Created database succesfully')
    resolve(e.target.result)
  }
  request.onerror = e => {
    console.error(e, 'Failed to open the database', TAG)
    reject(e)
  }
  request.onupgradeneeded = e => {
    console.log('ONUPGRADENEEDED')
    const db = e.target.result
    stores.forEach(store => {
      if (!db.objectStoreNames.contains(store)) {
        db.createObjectStore(store)
      }
    })
  }

  request.onblocked = e => {
    reject(e)
  }
})

class IndexedDBStorage {
  constructor (name) {
    this.name = name
    this.ready = db.then(db => {
      this.db = db
    })
  }

    get = key => this.ready.then(() => new Promise((resolve, reject) => {
      var transaction = this.db.transaction([this.name], 'readonly')
      transaction.onabort = function (e) {
        var error = e.target.error
        if (error.name === 'QuotaExceededError') {
          window.alert('Er ging iets mis om dat je helaas te weinig ruimte op je apparaat hebt.')
        }
        throw error
      }
      const store = transaction.objectStore(this.name)
      var request = store.get(key)
      request.onsuccess = function (e) { resolve(e.target.result) }
      request.onerror = reject
    }));

    set = (key, value) => this.ready.then(() => new Promise((resolve, reject) => {
      var transaction = this.db.transaction([this.name], 'readwrite')
      transaction.onabort = function (e) {
        var error = e.target.error
        if (error.name === 'QuotaExceededError') {
          window.alert('Er ging iets mis om dat je helaas te weinig ruimte op je apparaat hebt.')
        }
        throw error
      }
      const store = transaction.objectStore(this.name)
      var request = store.put(value, key)
      request.onsuccess = e => {
        resolve()
      }
      request.onerror = reject
    }))

    delete = (key) => this.ready.then(() => new Promise((resolve, reject) => {
      var transaction = this.db.transaction([this.name], 'readwrite')
      transaction.onabort = function (e) {
        var error = e.target.error
        if (error.name === 'QuotaExceededError') {
          window.alert('Er ging iets mis om dat je helaas te weinig ruimte op je apparaat hebt.')
        }
        throw error
      }
      const store = transaction.objectStore(this.name)
      var request = store.delete(key)
      request.onsuccess = resolve
      request.onerror = reject
    }))

    purgeDatabase = () => this.ready.then(() => new Promise((resolve, reject) => {
      var transaction = this.db.transaction([this.name], 'readwrite')
      transaction.onabort = function (e) {
        var error = e.target.error
        if (error.name === 'QuotaExceededError') {
          window.alert('Er ging iets mis om dat je helaas te weinig ruimte op je apparaat hebt.')
        }
        throw error
      }
      const store = transaction.objectStore(this.name)
      var request = store.clear()
      request.onsuccess = resolve
      request.onerror = reject
    }))

    deleteDatabase = () => {
      window.indexedDB.deleteDatabase(window.location.origin)
    }
}

window.appStore = new IndexedDBStorage(APP_STORE)
export default window.appStore
export const apiCacheStore = new IndexedDBStorage(CACHE_STORE)


import { createAsyncAction } from 'redux-promise-middleware-actions'
import { fetchRevenue as fetchRevenueService, downloadRevenueOverview as downloadRevenueOverviewService } from '../../../services/esserver/revenue'

export const fetchRevenue = createAsyncAction('FETCH_REVENUE', projectId => {
  return fetchRevenueService(projectId)
})

export const downloadRevenueOverview = createAsyncAction('FETCH_REVENUE_CSV', projectId => {
  return downloadRevenueOverviewService(projectId)
})

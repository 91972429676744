import React, { Component } from 'react'
import LoginFirstComponent from '../components/LoginFirst/LoginFirst'
import { compose } from 'redux'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import withInternationalization from '../hocs/Internationalization'

const stateSelector = createSelector(
  state => state.property1,
  (property1) => ({ property1 })
)

const enhance = compose(
  withInternationalization(),
  connect(
    stateSelector,
    dispatch => ({

    })
  )
)

const enhancedComponent = enhance(LoginFirstComponent)

enhancedComponent.propTypes = {

}

enhancedComponent.defaultProps = {

}

export default enhancedComponent

import { recoverAuth, refreshAuth } from '../../actions/esserver/auth'
import throttle from 'lodash/throttle'
import get from 'lodash/get'
import { toast } from 'react-toastify'
import { errorToString } from '../util'
import { parseJwt } from '../auth/jwt'
import Settings from '../settings/Settings'

const fiveMinutes = 1000 * 60 * 5
const tenMinutes = 1000 * 60 * 10

const isTokenInvalidErrors = err => {
  const errors = get(err, 'errors', [])
  if (Array.isArray(errors)) {
    if (errors.some(err => ['no-jwt-token', 'jwt-invalid-token'].includes(get(err, 'code')))) return true
  }
  return false
}

export default store => {
  setInterval(() => {
    const oldRawToken = Settings.getSetting('jwtToken')
    if (!oldRawToken) return
    const oldToken = parseJwt(oldRawToken)
    const expires = oldToken.exp * 1000
    if (Date.now() > expires - tenMinutes) {
      store.dispatch(refreshAuth())
    }
  }, fiveMinutes)

  store.subscribe(throttle(async () => {
    const {
      auth: {
        isAuthenticated,
        userHasAuthenticated,
        recoverFromAuth,
        isAuthenticating
      },
      internationalization: {
        messages
      }
    } = store.getState()

    if (isAuthenticated === false && userHasAuthenticated === false && recoverFromAuth === true && isAuthenticating === false) {
      try {
        const result = await store.dispatch(recoverAuth())
        console.log('result', result)
      } catch (err) {
        console.error('recoverAuth() error', err, isTokenInvalidErrors(err))

        if (!isTokenInvalidErrors(err)) {
          toast.error(errorToString(err))
        }
      }
    }
  }, 100))
}

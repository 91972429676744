import clsx from 'clsx'
import React from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import propTypes from 'prop-types'
import { isExternalURL } from '../../lib/util/url'

const RawLink = props => {
  const style = {
    textDecoration: 'none',
    color: 'inherit',
    ...props.style
  }
  const location = useLocation()
  const pathMatches = matchPath(location.pathname, props.to.toString())
  const className = clsx(props.className, pathMatches ? 'active' : 'not-active')
  const onClick = (event) => {
    if (props.to === location.pathname) {
      event.preventDefault()
    }
    if (props.onClick) {
      props.onClick(event)
    }
  }
  const { download, to, children, ...rest } = props
  const external = isExternalURL(to.toString())
  if (to.toString().startsWith('tel:')) {
    return (<a className={className} onClick={onClick} href={props.to.toString()} style={style} {...rest}>{children}</a>)
  } else if (props.to.toString().startsWith('mailto:')) {
    return (<a className={className} onClick={onClick} target="_blank" href={props.to.toString()} style={style} {...rest}>{children}</a>)
  } else if (external) {
    return (<a className={className} onClick={onClick} target="_blank" download={download} rel="noopener noreferrer" href={props.to.toString()} style={style} {...rest}>{children}</a>)
  } else {
    return (<Link className={className} to={to} onClick={onClick} {...rest} style={style}>
      {children}
    </Link>)
  }
}

RawLink.propTypes = {
  style: propTypes.object,
  className: propTypes.string,
  to: propTypes.string.isRequired,
  onClick: propTypes.func
}

RawLink.defaultProps = {
  to: ''
}

export default RawLink

import { register } from 'timeago.js'

export const nl = async () => {
  const [messages, icon, localeFunc] = await Promise.all([
    import('./_locales/nl.json'),
    import('svg-country-flags/svg/nl.svg'),
    import('timeago.js/lib/lang/nl')
  ])
  register('nl', localeFunc.default)
  console.log('nl', messages, icon, localeFunc)
  return {
    messages: messages.default,
    name: 'Nederlands',
    language: 'nl',
    icon: icon.default
  }
}

export const en = async () => {
  const [messages, icon, localeFunc] = await Promise.all([
    import('./_locales/en.json'),
    import('svg-country-flags/svg/us.svg'),
    import('timeago.js/lib/lang/en_US')
  ])
  console.log('en', messages, icon, localeFunc)
  register('en', localeFunc.default)
  return {
    messages: messages.default,
    name: 'English',
    language: 'en',
    icon: icon.default
  }
}


import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { compose } from 'redux';
import * as ROUTES from '../constants/routes';
import withAsyncComponent from '../hocs/AsyncComponent';
import AppliedRoute from './AppliedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
// import { HashRouter as Router } from 'react-router-dom'
import Router from './DebugRouter';

const AsyncNotFoundPage = withAsyncComponent(() => import('../containers/NotFoundPage'))
const AsyncDashboard = withAsyncComponent(() => import('../containers/Dashboard'))
const AsyncSettings = withAsyncComponent(() => import('../containers/Settings'))
const AsyncLogin = withAsyncComponent(() => import('../components/Login/Login'))
const AsyncRegister = withAsyncComponent(() => import('../components/Register/Register'))
const AsyncClientsRoot = withAsyncComponent(() => import('../components/ClientsRoot/ClientsRoot'))
const AsyncClient = withAsyncComponent(() => import('../containers/Client'))
const AsyncFullScreenLoader = withAsyncComponent(() => import('../components/FullScreenLoader/FullScreenLoader'))
const AsyncNotifications = withAsyncComponent(() => import('../components/Notifications/Notifications'))
const AsyncNotificationSender = withAsyncComponent(() => import('../components/NotificationSender/NotificationSender'))
const AsyncWebPushFeeds = withAsyncComponent(() => import('../components/WebPushFeeds/WebPushFeeds'))
const AsyncWebPushFeedEdit = withAsyncComponent(() => import('../containers/WebPushFeedEdit'))
const AsyncWebPushFeedNew = withAsyncComponent(() => import('../components/WebPushFeedNew/WebPushFeedNew'))
const AsyncLoginStates = withAsyncComponent(() => import('../lib/auth/LoginState'))
const AsyncAdministration = withAsyncComponent(() => import('../components/Administration/Administration'))
const AsyncDocuments = withAsyncComponent(() => import('../containers/Documents'))
const AsyncDocumentType = withAsyncComponent(() => import('./DocumentType'))
const AsyncDocumentTypeSpecific = withAsyncComponent(() => import('./DocumentTypeSpecific'))
const AsyncClientUsers = withAsyncComponent(() => import('./ClientUsersPage'))
const AsyncCalender = withAsyncComponent(() => import('./Calender'))
const AsyncCollectionEditorPage = withAsyncComponent(() => import('./CollectionEditor'))
const AsyncCollectionEditorCollectionItem = withAsyncComponent(() => import('./CollectionEditorCollectionItem'))
const AsyncProjects = withAsyncComponent(() => import('./Projects'))
const AsyncProject = withAsyncComponent(() => import('./Project'))
const AsyncCollectionItem = withAsyncComponent(() => import('./CollectionItemPage'))
const AsyncPiece = withAsyncComponent(() => import('./Piece'))
const AsyncUserProfile = withAsyncComponent(() => import('./UserProfile'))
const AsyncAuthorizeGithub = withAsyncComponent(() => import('./AuthorizeGithub'))
const AsyncNewEmbeds = withAsyncComponent(() => import('./NewEmbeds'))
const AsyncProjectEmbed = withAsyncComponent(() => import('./ProjectEmbed'))
const AsyncProjectMediaGalleryPage = withAsyncComponent(() => import('./ProjectMediaGalleryPage'))
const AsyncSoundtracksOverview = withAsyncComponent(() => import('./soundtracks/SoundtracksOverview'))
const AsyncResponsiveImagesOverviewPage = withAsyncComponent(() => import('./ResponsiveImagesOverviewPage'))
const AsyncSpecificImagePage = withAsyncComponent(() => import('./SpecificImagePage'))
const AsyncSoundtrackSpecificPage = withAsyncComponent(() => import('./soundtracks/SoundtrackSpecificPage'))
const TriggersPage = withAsyncComponent(() => import('./triggers/TriggersPage'))
const TriggerPage = withAsyncComponent(() => import('./triggers/TriggerPage'))
const AddGithubCommitsChangelogPage = withAsyncComponent(() => import('./AddGithubCommitsChangelogPage'))
const SpecificProjectGithubCommitsChangelogPage = withAsyncComponent(() => import('./SpecificProjectGithubCommitsChangelogPage'))
const SpecificFilePage = withAsyncComponent(() => import('./SpecificFilePage'))
const GoogleMapsHTMLSnippet = withAsyncComponent(() => import('../components/snippets/GoogleMapsHTMLSnippet/GoogleMapsHTMLSnippet'))
const ProjectPaymentMethods = withAsyncComponent(() => import('./ProjectPaymentMethods'))
const ProjectPaymentMethod = withAsyncComponent(() => import('./ProjectPaymentMethod'))

const Routes = props => {
  const childProps = {
    ...props.auth
  }

  return (
    <Router>
      <Switch>
        <AppliedRoute path={ROUTES.SIGN_IN} component={AsyncLogin} props={childProps} />
        <AppliedRoute path={ROUTES.REGISTER} component={AsyncRegister} props={childProps} />
        <AuthenticatedRoute exact path={ROUTES.DASHBOARD} component={AsyncDashboard} props={childProps} />
        <AppliedRoute path={ROUTES.SETTINGS} component={AsyncSettings} props={childProps} />

        <AuthenticatedRoute path={ROUTES.ADMINISTRATION_DOCUMENTS_TYPE_SPECIFIC} component={AsyncDocumentTypeSpecific} props={childProps} />
        <AuthenticatedRoute path={ROUTES.ADMINISTRATION_DOCUMENTS_TYPE} component={AsyncDocumentType} props={childProps} />
        <AuthenticatedRoute path={ROUTES.ADMINISTRATION_DOCUMENTS} component={AsyncDocuments} props={childProps} />
        <AuthenticatedRoute path={ROUTES.ADMINISTRATION} component={AsyncAdministration} props={childProps} />

        <AuthenticatedRoute props={childProps} path={ROUTES.USER} component={AsyncUserProfile} />

        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENT_CALENDER} component={AsyncCalender} />
        <AuthenticatedRoute props={childProps} path={ROUTES.WEBPUSHFEED_NEW} component={({ match }) => <AsyncWebPushFeedNew {...match.params} />} />
        <AuthenticatedRoute props={childProps} path={ROUTES.WEBPUSHFEED_EDIT} component={({ match }) => <AsyncWebPushFeedNew {...match.params} />} />
        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENT_USERS} component={AsyncClientUsers} />
        <AuthenticatedRoute props={childProps} path={ROUTES.SOUNDTRACK_SPECIFIC} component={AsyncSoundtrackSpecificPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.IMAGE_SPECIFIC} component={AsyncSpecificImagePage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_GITHUB_COMMITS_CHANGELOG} component={SpecificProjectGithubCommitsChangelogPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_NEW_GITHUB_COMMITS_CHANGELOG} component={AddGithubCommitsChangelogPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_IMAGES_OVERVIEW_PAGED} component={AsyncResponsiveImagesOverviewPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_IMAGES_OVERVIEW} component={AsyncResponsiveImagesOverviewPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SOUNDTRACK_OVERVIEW} component={AsyncSoundtracksOverview} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SPECIFIC_FILE} component={SpecificFilePage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_MEDIA_GALLERY_PAGE} component={AsyncProjectMediaGalleryPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_MEDIA_GALLERY} component={AsyncProjectMediaGalleryPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SPECIFIC_EMBED_SPECIFIC} component={AsyncProjectEmbed} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_SPECIFIC_NEW_EMBED} component={AsyncNewEmbeds} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_PAYMENT_METHODS_SPECIFIC} component={ProjectPaymentMethod} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_PAYMENT_METHODS} component={ProjectPaymentMethods} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_TRIGGER_SPECIFIC_PAGE} component={TriggerPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT_TRIGGERS_PAGE} component={TriggersPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECT} component={AsyncProject} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PROJECTS} component={AsyncProjects} />
        <AuthenticatedRoute props={childProps} path={ROUTES.PIECE_SPECIFIC} component={AsyncPiece} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTION_EDITOR_SPECIFIC} component={AsyncCollectionEditorCollectionItem} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTION_SPECIFIC} component={AsyncCollectionItem} />
        <AuthenticatedRoute props={childProps} path={ROUTES.COLLECTIONS} component={AsyncCollectionEditorPage} />
        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENT} component={({ match }) => <AsyncClient {...match.params} />} />
        <AuthenticatedRoute props={childProps} path={ROUTES.CLIENTS} component={AsyncClientsRoot} />
        <AuthenticatedRoute props={childProps} path={ROUTES.AUTHORIZE_GITHUB} component={AsyncAuthorizeGithub} />

        <AppliedRoute props={childProps} path={ROUTES.TOOLS_SNIPPETS_GOOGLE_MAPS_HTML} component={GoogleMapsHTMLSnippet} />
        <AppliedRoute props={childProps} path="/" component={AsyncNotFoundPage} />
      </Switch>
    </Router >
  )
}

export default compose(
  connect(
    ({ auth }) => ({
      auth
    }),
    null
  )
)(Routes)


import * as tst from 'redux-json-api';
import { fetchOpenApiDefinition } from '../../actions/esserver/openapi';
import { BASE_URL } from '../../constants/api';
import { CMS_HOST } from '../../constants/settings';
import { settingGet } from '../redux/util';

console.log('setAxiosConfig', tst)

export default async store => {
  const cmsHost = await settingGet(CMS_HOST)
  const baseURL = new URL(BASE_URL, cmsHost).href

  store.dispatch(tst.setAxiosConfig({
    baseURL,
    'X-Apicache-Bypass': true
  }))

  let prevState = store.getState()

  const listen = (action) => {
    const state = store.getState()

    if (state.auth.isAuthenticated && !prevState.auth.isAuthenticated) {
      unsubscribe()
      store.dispatch(fetchOpenApiDefinition())
    }

    prevState = state
  }

  const unsubscribe = store.subscribe(listen)
  listen()
}

import { fetchRevenue } from '../../actions/esserver/revenue'

const initialState = {
  sync: false,
  loading: false,
  data: null,
  errors: null,
  error: false
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case fetchRevenue.pending.toString(): {
      return { ...state, sync: false, loading: true, data: null, errors: null, error: false }
    }
    case fetchRevenue.rejected.toString(): {
      return { ...state, sync: false, loading: false, data: null, errors: payload, error: false }
    }
    case fetchRevenue.fulfilled.toString(): {
      return { ...state, sync: true, loading: false, data: payload.data, errors: null, error: false }
    }
    default: {
      return state
    }
  }
}


import { combineReducers } from 'redux'

import feedsReducer from './feeds'
import subscriptionsReducer from './subscriptions'

export default combineReducers({
  feeds: feedsReducer,
  subscriptions: subscriptionsReducer
})

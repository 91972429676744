import { settingGet } from '../../../lib/redux/util'

export const fetchOpenApiDefinition = async () => {
  const cmsHost = await settingGet('cmsHost')
  const response = await window.fetch(`${cmsHost}/api-doc.json`, {})
  const result = await response.json()

  return {
    definition: result
  }
}

import { settingGet } from '../../lib/redux/util'

const AUTHORIZE_BASE_URL = 'api/googleapis/auth/authorize'
const TOKEN_BASE_URL = 'api/googleapis/auth/token'

export const GOOGLEAPIS_GET_AUTH_TOKEN = 'GOOGLEAPIS_GET_AUTH_TOKEN'
export const GOOGLEAPIS_GET_AUTH_TOKEN_FULFILLED = 'GOOGLEAPIS_GET_AUTH_TOKEN_FULFILLED'
export const GOOGLEAPIS_GET_AUTH_TOKEN_REJECTED = 'GOOGLEAPIS_GET_AUTH_TOKEN_REJECTED'
export const GOOGLEAPIS_GET_AUTH_TOKEN_PENDING = 'GOOGLEAPIS_GET_AUTH_TOKEN_PENDING'

export const authorize = () => dispatch => dispatch({
  type: GOOGLEAPIS_GET_AUTH_TOKEN,
  payload: getAuthToken()
}).catch(console.error)

const getCodeFromPopup = cmsHost => new Promise((resolve, reject) => {
  const url = new URL(AUTHORIZE_BASE_URL, cmsHost).href
  let done = false
  try {
    const listener = event => {
      if (done) return
      const { data } = event
      if (data.type !== 'oauth-callbacks-googleapis-popup') return
      const code = data.payload.code
      done = true
      window.clearInterval(pollTimer)
      window.removeEventListener('message', listener)
      resolve(code)
    }

    window.addEventListener('message', listener)

    const popup = window.open(url, '_blank', 'menubar=0,resizable=0,width=350,height=600', false)

    const handlePopupFailed = () => {
      if (done) return
      done = true
      window.removeEventListener('message', listener)

      const error = {
        title: 'Popup window was closed.',
        detail: 'Popup window was closed before we could verify with google.',
        code: 'googleapis-authorize-popup-closed'
      }
      reject(error)
    }

    var pollTimer = window.setInterval(() => {
      if (popup.closed !== false) { // !== is required for compatibility with Opera
        window.clearInterval(pollTimer)
        handlePopupFailed()
      }
    }, 200)
  } catch (err) {
    reject(err)
  }
})

const getAuthToken = async () => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  return getCodeFromPopup(cmsHost)
    .then(async code => {
      const url = new URL(TOKEN_BASE_URL, cmsHost).href

      const response = await window.fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `JWT ${jwtToken}`,
          'Content-Type': 'application/json',
          'X-Apicache-Bypass': true
        },
        body: JSON.stringify({ code })
      })
      const data = response.json()
      if (!response.ok) throw data
      const token = data
      return token
    })
}

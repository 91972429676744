import { settingGet } from '../../../lib/redux/util'
import { getJsonWithJWT, getWithJWT } from '../../../lib/http/fetch'

export const fetchRevenue = async projectId => {
  const cmsHost = await settingGet('cmsHost')
  return getJsonWithJWT(`${cmsHost}/api/administration/revenue/${projectId}`)
}

export const downloadRevenueOverview = async projectId => {
  const cmsHost = await settingGet('cmsHost')
  const response = await getWithJWT(`${cmsHost}/api/administration/revenue/${projectId}/csv`)
  const blob = await response.blob()
  var url = window.URL.createObjectURL(blob)
  var a = document.createElement('a')
  a.href = url
  a.download = projectId + '-revenue.csv'
  document.body.appendChild(a)
  a.click()
  a.remove()
  return true
}

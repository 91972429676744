import { fetchOpenApiDefinition } from '../actions/esserver/openapi'

const API_READ_FAILED = 'API_READ_FAILED'
const API_READ = 'API_READ'
const API_WILL_READ = 'API_WILL_READ'

const initialState = {
  status: {},
  openapi: {
    sync: false,
    errors: null,
    loading: false,
    error: false,
    data: null
  }
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case API_READ: {
      const status = { ...state.status, [payload.finalEndpoint.split('?')[0]]: { payload: action.payload, type: action.type, time: Date.now() } }
      return { ...state, status }
    }
    case API_WILL_READ: {
      const status = { ...state.status, [payload.split('?')[0]]: { payload: action.payload, type: action.type, time: Date.now() } }
      return { ...state, status }
    }
    case API_READ_FAILED: {
      const status = { ...state.status, [payload.endpoint.split('?')[0]]: { payload: action.payload, type: action.type, time: Date.now() } }
      return { ...state, status }
    }
    case fetchOpenApiDefinition.pending.toString(): {
      const openapi = { ...state.openapi, sync: false, loading: true, data: null, errors: null, error: false }
      return { ...state, openapi }
    }
    case fetchOpenApiDefinition.rejected.toString(): {
      const openapi = { ...state.openapi, sync: false, loading: false, data: null, errors: payload, error: false }
      return { ...state, openapi }
    }
    case fetchOpenApiDefinition.fulfilled.toString(): {
      const openapi = { ...state.openapi, sync: true, loading: false, data: payload, errors: null, error: false }
      return { ...state, openapi }
    }
    default: {
      return state
    }
  }
}

import { GOOGLEAPIS_GET_AUTH_TOKEN_PENDING, GOOGLEAPIS_GET_AUTH_TOKEN_FULFILLED, GOOGLEAPIS_GET_AUTH_TOKEN_REJECTED } from '../../actions/googleapis/auth'

const initialState = {
  isAuthorizing: false,
  isAuthorized: false,
  hasAuthorized: false,
  token: null,
  error: null
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GOOGLEAPIS_GET_AUTH_TOKEN_PENDING: {
      return Object.assign({}, state, {
        isAuthorizing: true,
        isAuthorized: false,
        hasAuthorized: false,
        token: null,
        error: null
      })
    }

    case GOOGLEAPIS_GET_AUTH_TOKEN_FULFILLED: {
      return Object.assign({}, state, {
        isAuthorizing: false,
        isAuthorized: true,
        hasAuthorized: true,
        error: null,
        token: payload
      })
    }

    case GOOGLEAPIS_GET_AUTH_TOKEN_REJECTED: {
      return Object.assign({}, state, {
        isAuthorizing: false,
        isAuthorized: false,
        hasAuthorized: false,
        token: null,
        error: payload
      })
    }

    default: {
      return state
    }
  }
}


import { login as _login, register as _register, refresh as _refresh } from '../../lib/auth/http'
import { getJsonWithJWT } from '../../lib/http/fetch'
import { JWT_TOKEN, CMS_HOST } from '../../constants/settings'
import Settings from '../../lib/settings/Settings'
import get from 'lodash/get'

export const login = async ({ email, password, recaptcha }) => {
  const result = await _login({ email, password, recaptcha })
  return result.data
}

export const register = async ({ email, username, password, recaptcha }) => {
  const result = await _register({ email, username, password, recaptcha })
  return result.data
}

export const refresh = async (oldToken) => {
  const result = await _refresh(oldToken)
  return result.data
}

const isTokenExpiredError = err => {
  const errors = get(err, 'errors', [])
  if (Array.isArray(errors)) {
    if (errors.some(err => get(err, 'code') === 'token-expired-error')) return true
  }
  return false
}

export const recoverAuth = async () => {
  const cmsHost = await Settings.getSetting(CMS_HOST)
  const oldJwtToken = await Settings.getSetting(JWT_TOKEN)
  let data
  try {
    const { data: userData } = await getJsonWithJWT(`${cmsHost}/api/auth/user`)
    data = { jwtToken: oldJwtToken, user: userData }
  } catch (err) {
    if (isTokenExpiredError(err)) {
      data = await refresh(oldJwtToken)
    } else {
      throw err
    }
  }
  return data
}

import { FETCH_ESS_DOCUMENT_PENDING, FETCH_ESS_DOCUMENT_FULFILLED, FETCH_ESS_DOCUMENT_REJECTED } from '../../actions/esserver/documents'

const initialState = {
  documents: null,
  isLoading: false,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ESS_DOCUMENT_PENDING: {
      return Object.assign({}, state, {
        isLoading: true
      })
    }

    case FETCH_ESS_DOCUMENT_FULFILLED: {
      return Object.assign({}, state, {
        isLoading: false,
        documents: action.payload,
        error: null
      })
    }

    case FETCH_ESS_DOCUMENT_REJECTED: {
      return Object.assign({}, state, {
        isLoading: false,
        error: action.payload
      })
    }

    default: {
      return state
    }
  }
}

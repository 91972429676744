import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import store from './store'
import { Provider as ReduxProvider } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import { toast, ToastContainer } from 'react-toastify'
import bootstrapRedux from './lib/bootstrap-redux'
import { ThemeProvider } from '@material-ui/styles'

bootstrapRedux(store)

// Call it once in your app. At the root of your app is the best place
toast.configure()

ReactDOM.render(
  <ReduxProvider store={store}>
    <ToastContainer />
    <App />
  </ReduxProvider>
  , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import { settingGet } from '../../../lib/redux/util'
import { putWithJWT, deleteWithJWT, postWithJWT } from '../../../lib/http/fetch'

export const fetchWebPushFeeds = async ({ clientId }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/notifications/webpushfeeds/client/${clientId}`, {
    headers: {
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    }
  })
  const json = await response.json()
  if (response.status >= 400) throw json
  else return json
}

export const putWebPushFeed = async ({ data }) => {
  const cmsHost = await settingGet('cmsHost')
  const response = await putWithJWT(`${cmsHost}/api/notifications/webpushfeeds/${data.id}`, data)
  const json = await response.json()
  if (response.status >= 400) throw json
  else return json
}

export const deleteWebPushFeed = async ({ feedid }) => {
  const cmsHost = await settingGet('cmsHost')
  const response = await deleteWithJWT(`${cmsHost}/api/notifications/webpushfeeds/${feedid}`)
  const json = await response.json()
  if (response.status >= 400) throw json
  else return json
}

export const postWebPushFeed = async ({ clientId, data }) => {
  const cmsHost = settingGet('cmsHost')
  const response = await postWithJWT(`${cmsHost}/api/notifications/webpushfeeds/client/${clientId}`, data)
  const json = await response.json()
  if (response.status >= 400) throw json
  else return json
}

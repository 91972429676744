import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import color from 'color'

const background = c => `linear-gradient(45deg, ${color(c).lighten(0.1)} 30%, ${color(c).darken(0.1)} 90%)`

const boxShadow = c => `0 3px 5px 2px ${color(c).alpha(0.1)}`

const useStyles = makeStyles(theme => ({
  root: {
    background: props => background(props.color || theme.palette.primary.main),
    border: 0,
    borderRadius: 3,
    boxShadow: props => boxShadow(props.color || theme.palette.primary.main),
    color: 'white',
    height: 48,
    padding: '0 30px',
    margin: '8px 8px 8px 0'
  }
}))

function AwesomeButton (props) {
  const { color, ...other } = props
  const classes = useStyles(props)
  return <Button className={classes.root} {...other} />
}

AwesomeButton.propTypes = {
  color: PropTypes.string
}

AwesomeButton.defaultProps = {
}

export default AwesomeButton

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import styles from './FullScreenLoader.module.css'

class FullScreenLoader extends Component {
  constructor (props) {
    super()
    this.state = {
    }
  }

  componentWillUnmount () {
  }

  render () {
    return (
      <div className={styles.filler}>
        <CircularProgress />
        <h2 className={styles.text}>{this.props.children || this.props.text}</h2>
      </div>
    )
  }
}

export default FullScreenLoader

import { fetchWebPushClientSubscriptions as fetchWebPushClientSubscriptionsService } from '../../../services/esserver/web-push/subscription'

export const FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS = 'FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS'
export const FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_PENDING = 'FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_PENDING'
export const FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_FULFILLED = 'FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_FULFILLED'
export const FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_REJECTED = 'FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_REJECTED'

export const fetchWebPushClientSubscriptions = ({ clientId }) => dispatch => dispatch({
  meta: { clientId },
  type: FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS,
  payload: fetchWebPushClientSubscriptionsService({ clientId })
})

import { settingGet } from '../../../lib/redux/util'

export const fetchWebPushClientSubscriptions = async ({ clientId }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/notifications/webpushsubscribers/client/${clientId}/count`, {
    headers: {
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    }
  })
  const json = await response.json()
  if (response.status >= 400) throw json
  else return json
}

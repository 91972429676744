import { SET_DOCUMENTS_VAULT_PAGINATION_SETTINGS } from '../../actions/esserver/documents/pagination'

const defaultState = {
  perPage: 25
}

export default (state = defaultState, action) => {
  const { payload } = action

  switch (action.type) {
    case SET_DOCUMENTS_VAULT_PAGINATION_SETTINGS: {
      return Object.assign({}, state, payload)
    }
    default: {
      return state
    }
  }
}

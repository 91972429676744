import { fetchWebPushFeeds as fetchWebPushFeedsService, putWebPushFeed as putWebPushFeedService, postWebPushFeed as postWebPushFeedService } from '../../../services/esserver/web-push/feeds'

export const FETCH_ESS_CMS_WEB_PUSH_FEEDS = 'FETCH_ESS_CMS_WEB_PUSH_FEEDS'
export const FETCH_ESS_CMS_WEB_PUSH_FEEDS_PENDING = 'FETCH_ESS_CMS_WEB_PUSH_FEEDS_PENDING'
export const FETCH_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED = 'FETCH_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED'
export const FETCH_ESS_CMS_WEB_PUSH_FEEDS_REJECTED = 'FETCH_ESS_CMS_WEB_PUSH_FEEDS_REJECTED'

export const fetchWebPushFeeds = ({ clientId }) => dispatch => dispatch({
  meta: { clientId },
  type: FETCH_ESS_CMS_WEB_PUSH_FEEDS,
  payload: fetchWebPushFeedsService({ clientId })
})

export const PUT_ESS_CMS_WEB_PUSH_FEEDS = 'PUT_ESS_CMS_WEB_PUSH_FEEDS'
export const PUT_ESS_CMS_WEB_PUSH_FEEDS_PENDING = 'PUT_ESS_CMS_WEB_PUSH_FEEDS_PENDING'
export const PUT_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED = 'PUT_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED'
export const PUT_ESS_CMS_WEB_PUSH_FEEDS_REJECTED = 'PUT_ESS_CMS_WEB_PUSH_FEEDS_REJECTED'

export const putFeed = ({ data }) => dispatch => dispatch({
  type: FETCH_ESS_CMS_WEB_PUSH_FEEDS,
  meta: { id: data.id },
  payload: putWebPushFeedService({ data })
})

export const POST_ESS_CMS_WEB_PUSH_FEEDS = 'POST_ESS_CMS_WEB_PUSH_FEEDS'
export const POST_ESS_CMS_WEB_PUSH_FEEDS_PENDING = 'POST_ESS_CMS_WEB_PUSH_FEEDS_PENDING'
export const POST_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED = 'POST_ESS_CMS_WEB_PUSH_FEEDS_FULFILLED'
export const POST_ESS_CMS_WEB_PUSH_FEEDS_REJECTED = 'POST_ESS_CMS_WEB_PUSH_FEEDS_REJECTED'

export const postFeed = ({ clientId, data }) => dispatch => dispatch({
  type: FETCH_ESS_CMS_WEB_PUSH_FEEDS,
  meta: { clientId },
  payload: postWebPushFeedService({ clientId, data })
})

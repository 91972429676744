import { GITHUB_GET_OAUTH_TOKEN_FULFILLED, GITHUB_GET_OAUTH_TOKEN_PENDING, GITHUB_GET_OAUTH_TOKEN_REJECTED } from '../../actions/github/oauth'

const initialState = {
  isAuthorizing: false,
  isAuthorized: false,
  hasAuthorized: false,
  token: null,
  error: null
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case GITHUB_GET_OAUTH_TOKEN_PENDING: {
      return Object.assign({}, state, {
        isAuthorizing: true,
        isAuthorized: false,
        hasAuthorized: false,
        token: null,
        error: null
      })
    }

    case GITHUB_GET_OAUTH_TOKEN_FULFILLED: {
      return Object.assign({}, state, {
        isAuthorizing: false,
        isAuthorized: true,
        hasAuthorized: true,
        error: null,
        token: payload
      })
    }

    case GITHUB_GET_OAUTH_TOKEN_REJECTED: {
      return Object.assign({}, state, {
        isAuthorizing: false,
        isAuthorized: false,
        hasAuthorized: false,
        token: null,
        error: payload
      })
    }

    default: {
      return state
    }
  }
}

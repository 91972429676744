
import throttle from 'lodash/throttle'
import bootstrapAuth from './auth'
import bootstrapReduxJsonApi from './redux-json-api'
import bootstrapInternationalization from './internationalization'

export default store => {
  bootstrapAuth(store)
  bootstrapReduxJsonApi(store)
  bootstrapInternationalization(store)

  store.subscribe(throttle(() => {

  }, 100))
}

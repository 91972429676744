import { UPDATE_LANGUAGE } from '../actions/internationalization'

const initialState = {
  language: null,
  messages: {}
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case UPDATE_LANGUAGE: {
      const { messages, name, icon, language } = payload
      return Object.assign({ }, state, { language, name, messages, icon })
    }
    default: {
      return state
    }
  }
}

import { settingGet } from '../../lib/redux/util'
import { BASE_URL } from '../../constants/api'

export const fetchLatestDocuments = async ({ amount = 10, projectId } = {}) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}${BASE_URL}projects/${projectId}/relationships/documents`, {
    headers: {
      Authorization: `JWT ${jwtToken}`
    }
  })
  const result = await response.json()
  return result
}

export const fetchDocument = async ({ id }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/administration/documents/${id}?include=documentRenderer`, {
    headers: {
      Authorization: `JWT ${jwtToken}`,
      'X-Apicache-Bypass': true
    }
  })
  const result = await response.json()
  return result
}

export const createDocument = async ({ logo, signature, structure, docType, clientId }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/administration/documents/${docType}`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${jwtToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Apicache-Bypass': true
    },
    body: JSON.stringify({
      logo,
      signature,
      structure,
      clientId
    })
  })
  const result = await response.json()
  return result
}

export const updateDocument = async ({ logo, signature, structure, id }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/administration/documents/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `JWT ${jwtToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Apicache-Bypass': true
    },
    body: JSON.stringify({
      logo,
      signature,
      structure
    })
  })
  const result = await response.json()
  return result
}

export const generateDocumentHtml = async ({ logo, signature, structure, docType }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/administration/documents/generate-immediate/${docType}`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${jwtToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Apicache-Bypass': true
    },
    body: JSON.stringify({
      logo,
      signature,
      structure
    })
  })
  let result
  try {
    result = await response.json()
  } catch (err) {
    console.error(err)
    window.alert('The server is returning invalid data.')
  }
  if (!response.ok) {
    throw result
  }
  return result
}

export const generateDocumentHtmlWithId = async ({ logo, signature, structure, id, docType }) => {
  const cmsHost = await settingGet('cmsHost')
  const jwtToken = await settingGet('jwtToken')
  const response = await window.fetch(`${cmsHost}/api/administration/documents/${docType}/${id}/generate-immediate/`, {
    method: 'POST',
    headers: {
      Authorization: `JWT ${jwtToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Apicache-Bypass': true
    },
    body: JSON.stringify({
      logo,
      signature,
      structure
    })
  })
  let result
  try {
    result = await response.json()
  } catch (err) {
    console.error(err)
    window.alert('The server is returning invalid data.')
  }
  if (!response.ok) {
    throw result
  }
  return result
}


import React, { Component } from 'react';
import FullScreenError from '../components/FullScreenError/FullScreenError';
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader';
import { errorToString, logError } from '../lib/util';

const cachedComponentsRegistryKeys = []
const cachedComponentsRegistryValues = []

const cachedPromisesRegistryKeys = []
const cachedPromisesRegistryValues = []

export default function withAsyncComponent (importComponent, loadingComponent = FullScreenLoader, errorComponent = FullScreenError) {
  class AsyncComponent extends Component {
    constructor (props) {
      super(props)

      const index = cachedComponentsRegistryKeys.indexOf(importComponent)

      console.log(index >= 0 ? 'Loaded async component from component registry cache' : 'Waiting for async component to download')

      this.state = {
        component: index >= 0 ? cachedComponentsRegistryValues[index] : loadingComponent,
        error: undefined
      }
    }

    async componentDidMount () {
      if (this.state.component !== loadingComponent) return
      try {
        const index = cachedPromisesRegistryKeys.indexOf(importComponent)

        const promise = index >= 0
          ? cachedPromisesRegistryValues[index]
          : importComponent().then(val => console.log('import component', index) || val)

        if (index === -1) {
          cachedPromisesRegistryKeys.push(importComponent)
          cachedPromisesRegistryValues.push(promise)
        }

        const { default: component } = await promise

        cachedComponentsRegistryKeys.push(importComponent)
        cachedComponentsRegistryValues.push(component)

        this.setState({
          component: component
        })
      } catch (err) {
        logError(err)
        this.setState({
          component: errorComponent,
          error: errorToString(err)
        })
      }
    }

    render () {
      const C = this.state.component

      return C ? <C error={this.state.error} message={this.state.error} text={this.state.error} {...this.props} /> : null
    }
  }

  return AsyncComponent
}

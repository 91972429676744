import { RECOVER_AUTH_PENDING, RECOVER_AUTH_FULFILLED, REFRESH_AUTH_FULFILLED, REFRESH_AUTH_PENDING, REFRESH_AUTH_REJECTED, LOGOUT, RECOVER_AUTH_REJECTED, LOGIN_AUTH_FULFILLED, LOGIN_AUTH_REJECTED, LOGIN_AUTH_PENDING } from '../actions/esserver/auth'
import get from 'lodash/get'

const initialState = {
  isAuthenticating: false,
  isAuthenticated: false,
  userHasAuthenticated: false,
  recoverFromAuth: true,
  authData: null,
  refreshedToken: false,
  errors: []
}

export default (state = initialState, action) => {
  const { payload } = action

  switch (action.type) {
    case RECOVER_AUTH_PENDING: {
      return Object.assign({}, state, {
        recoverFromAuth: true,
        userHasAuthenticated: false,
        isAuthenticating: true,
        isAuthenticated: false,
        isLoading: true
      })
    }

    case RECOVER_AUTH_FULFILLED: {
      return Object.assign({}, state, {
        recoverFromAuth: true,
        userHasAuthenticated: true,
        isAuthenticating: false,
        isAuthenticated: true,
        authData: payload,
        isLoading: false,
        errors: []
      })
    }

    case RECOVER_AUTH_REJECTED: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        userHasAuthenticated: false,
        isAuthenticating: false,
        isAuthenticated: false,
        isLoading: false,
        errors: payload.errors,
        authData: null
      })
    }

    case LOGIN_AUTH_PENDING: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        userHasAuthenticated: false,
        isAuthenticating: true,
        isAuthenticated: false,
        isLoading: true
      })
    }

    case LOGIN_AUTH_FULFILLED: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        userHasAuthenticated: true,
        isAuthenticating: false,
        isAuthenticated: true,
        authData: payload,
        isLoading: false,
        errors: []
      })
    }

    case LOGIN_AUTH_REJECTED: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        userHasAuthenticated: true,
        isAuthenticating: false,
        isAuthenticated: false,
        isLoading: false,
        errors: payload.errors,
        authData: null
      })
    }

    case REFRESH_AUTH_PENDING: {
      // this can happen in the background
      // so don't set any other fields
      // as it may hinder the user experience
      // only if we are unable to refresh,
      // we should set the isAuthenticated to false
      return Object.assign({}, state, {
        isRefreshing: true
      })
    }

    case REFRESH_AUTH_REJECTED: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        userHasAuthenticated: false,
        isAuthenticating: false,
        isAuthenticated: false,
        isLoading: false,
        errors: payload.errors,
        authData: null,
        isRefreshing: false
      })
    }

    case REFRESH_AUTH_FULFILLED: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        refreshedToken: true,
        userHasAuthenticated: true,
        isAuthenticating: false,
        isAuthenticated: true,
        authData: payload,
        isLoading: false,
        errors: [],
        isRefreshing: false
      })
    }

    case LOGOUT: {
      return Object.assign({}, state, {
        recoverFromAuth: false,
        userHasAuthenticated: false,
        isAuthenticating: false,
        isAuthenticated: false,
        isLoading: false,
        errors: null,
        authData: null
      })
    }

    case 'API_READ_FAILED':
    case 'API_CREATE_FAILED':
    case 'API_DELETE_FAILED':
    case 'API_UPDATE_FAILED': {
      const tokenExpiredError = get(payload, 'response.data.errors', []).find(error => error.code === 'token-expired-error')
      if (tokenExpiredError) {
        return Object.assign({}, state, {
          recoverFromAuth: false,
          userHasAuthenticated: false,
          isAuthenticating: false,
          isAuthenticated: false,
          isLoading: false,
          errors: null,
          authData: null
        })
      }
      return state
    }

    default: {
      return state
    }
  }
}

import { postJson } from '../http/fetch'
import { CMS_HOST } from '../../constants/settings'
import Settings from '../settings/Settings'

const clientId = 4

export const login = async ({ email, password, recaptcha }) => {
  const data = { email, password, 'g-recaptcha-response': recaptcha }
  const cmsHost = await Settings.getSetting(CMS_HOST)
  return postJson(`${cmsHost}/api/auth/${clientId}/login`, data)
}

export const register = async ({ email, username, password, recaptcha }) => {
  const data = { email, password, username, 'g-recaptcha-response': recaptcha }
  const cmsHost = await Settings.getSetting(CMS_HOST)
  return postJson(`${cmsHost}/api/auth/${clientId}/register`, data)
}

export const refresh = async (oldJwtToken) => {
  const data = { jwtToken: oldJwtToken }
  const cmsHost = await Settings.getSetting(CMS_HOST)
  return postJson(`${cmsHost}/api/auth/${clientId}/refresh`, data)
}

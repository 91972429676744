import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

import { SET_DOCUMENTS_FILTER } from '../../actions/esserver/documents/filters'

const defaultState = {}

export default (state = defaultState, action) => {
  const { payload } = action

  switch (action.type) {
    case SET_DOCUMENTS_FILTER: {
      const { key, type, value } = payload
      const filters = state[type] || {}
      const newFilters = { ...filters, [key]: value }
      const newState = { ...state, [type]: omitBy(newFilters, isUndefined) }
      return { ...state, ...omitBy(newState, isUndefined) }
    }
    default: {
      return state
    }
  }
}

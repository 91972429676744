
import DefaultSettings from './DefaultSettings'

const prefix = 'cms-client-v1-'

class Settings {
  getAllSettings () {
    const keys = Object.keys(DefaultSettings)
    const set = {}
    keys.forEach(key => {
      const json = window.localStorage.getItem(prefix + key)
      const val = json !== null ? JSON.parse(json) : DefaultSettings[key]
      set[key] = val
    })
    return set
  }

  delete (key) {
    window.localStorage.removeItem(prefix + key)
  }

  getSetting (key) {
    const json = window.localStorage.getItem(prefix + key)
    const val = json !== null ? JSON.parse(json) : DefaultSettings[key]
    return val
  }

  persist (set) {
    Object.keys(DefaultSettings).filter(key => set[key] !== undefined).forEach(key => {
      window.localStorage.setItem(prefix + key, JSON.stringify(set[key]))
    })
  }
}

export default new Settings()

import { FETCH_ESS_SPECIFIC_DOCUMENT_REJECTED, FETCH_ESS_SPECIFIC_DOCUMENT_PENDING, FETCH_ESS_SPECIFIC_DOCUMENT_FULFILLED } from '../../actions/esserver/documents'

const initialState = {
  documents: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ESS_SPECIFIC_DOCUMENT_PENDING: {
      const { id } = action.meta
      const documents = Object.assign({}, state.documents, {
        [id]: {
          loading: true
        }
      })
      return Object.assign({}, state, { documents })
    }

    case FETCH_ESS_SPECIFIC_DOCUMENT_FULFILLED: {
      const { id } = action.meta
      const documents = Object.assign({}, state.documents, {
        [id]: {
          document: action.payload,
          loading: false,
          error: null
        }
      })
      return Object.assign({}, state, { documents })
    }

    case FETCH_ESS_SPECIFIC_DOCUMENT_REJECTED: {
      const { id } = action.meta
      const documents = Object.assign({}, state.documents, {
        [id]: {
          loading: false,
          error: action.payload
        }
      })
      return Object.assign({}, state, { documents })
    }

    default: {
      return state
    }
  }
}

import { ThemeProvider } from '@material-ui/core';
import get from 'lodash/get';
import once from 'lodash/once';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Routes from './containers/Routes';
import theme from './theme';

const showFirstLoadMessagesOnce = once((messages) => {
  const loadWithSpecificBrowser = get(messages, 'loadWithSpecificBrowser.message')

  toast.info(loadWithSpecificBrowser)
})

const App = props => {
  useEffect(() => {
    if (props.messages) showFirstLoadMessagesOnce(props.messages)
  }, [props.messages])

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Laika CMS</title>
        <meta name="description" content="Laika CMS" />
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      <Routes />
    </ThemeProvider>
  )
}

export default App

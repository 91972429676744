import { FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_FULFILLED, FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_PENDING, FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_REJECTED } from '../../actions/esserver/web-push/subscriptions'

const initialState = {
  subscriptionCountByClientId: {
    // test: {
    //     count: null,
    //     error: false,
    //     isLoading: true
    // },
    // test2: {
    //     count: 20,
    //     error: false,
    //     isLoading: false
    // }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_PENDING: {
      const { meta: { clientId } } = action
      const subscriptionCountByClientId = Object.assign({}, state.subscriptionCountByClientId, {
        [clientId]: {
          count: null,
          error: null,
          isLoading: true
        }
      })
      return Object.assign({}, state, { subscriptionCountByClientId })
    }

    case FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_FULFILLED: {
      const { meta: { clientId } } = action
      const subscriptionCountByClientId = Object.assign({}, state.subscriptionCountByClientId, {
        [clientId]: {
          count: action.payload,
          error: null,
          isLoading: false
        }
      })
      return Object.assign({}, state, { subscriptionCountByClientId })
    }

    case FETCH_ESS_CMS_WEB_PUSH_CLIENT_SUBSCRIPTIONS_REJECTED: {
      const { meta: { clientId } } = action
      const subscriptionCountByClientId = Object.assign({}, state.subscriptionCountByClientId, {
        [clientId]: {
          error: action.payload,
          isLoading: false
        }
      })
      return Object.assign({}, state, { subscriptionCountByClientId })
    }

    default: {
      return state
    }
  }
}


import { combineReducers } from 'redux'
import { reducer as api } from 'redux-json-api'

import administrationReducer from './administration'
import authReducer from './auth'
import webPushReducer from './web-push'
import documentsReducer from './documents'
import internationalizationReducer from './internationalization'
import googleapisReducer from './googleapis'
import githubReducer from './github'
import apiMetaReducer from './api-meta'

export default combineReducers({
  administration: administrationReducer,
  auth: authReducer,
  webPush: webPushReducer,
  documents: documentsReducer,
  internationalization: internationalizationReducer,
  googleapis: googleapisReducer,
  github: githubReducer,
  api,
  apiMeta: apiMetaReducer
})

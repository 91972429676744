import { fetchLatestDocuments as fetchLatestDocumentsService, fetchDocument as fetchDocumentService, updateDocument as updateDocumentService } from '../../../services/esserver/documents'

export const FETCH_ESS_DOCUMENT = 'FETCH_ESS_DOCUMENT'
export const FETCH_ESS_DOCUMENT_PENDING = 'FETCH_ESS_DOCUMENT_PENDING'
export const FETCH_ESS_DOCUMENT_FULFILLED = 'FETCH_ESS_DOCUMENT_FULFILLED'
export const FETCH_ESS_DOCUMENT_REJECTED = 'FETCH_ESS_DOCUMENT_REJECTED'

export const fetchLatestDocuments = ({ amount, clientId }) => dispatch => dispatch({
  type: FETCH_ESS_DOCUMENT,
  meta: { amount },
  payload: fetchLatestDocumentsService({ amount, clientId })
}).catch(console.error)

export const FETCH_ESS_SPECIFIC_DOCUMENT = 'FETCH_ESS_SPECIFIC_DOCUMENT'
export const FETCH_ESS_SPECIFIC_DOCUMENT_PENDING = 'FETCH_ESS_SPECIFIC_DOCUMENT_PENDING'
export const FETCH_ESS_SPECIFIC_DOCUMENT_FULFILLED = 'FETCH_ESS_SPECIFIC_DOCUMENT_FULFILLED'
export const FETCH_ESS_SPECIFIC_DOCUMENT_REJECTED = 'FETCH_ESS_SPECIFIC_DOCUMENT_REJECTED'

export const fetchDocument = ({ id }) => dispatch => dispatch({
  type: FETCH_ESS_SPECIFIC_DOCUMENT,
  meta: { id },
  payload: fetchDocumentService({ id })
}).catch(console.error)

export const UPDATE_ESS_SPECIFIC_DOCUMENT = 'UPDATE_ESS_SPECIFIC_DOCUMENT'
export const UPDATE_ESS_SPECIFIC_DOCUMENT_PENDING = 'UPDATE_ESS_SPECIFIC_DOCUMENT_PENDING'
export const UPDATE_ESS_SPECIFIC_DOCUMENT_FULFILLED = 'UPDATE_ESS_SPECIFIC_DOCUMENT_FULFILLED'
export const UPDATE_ESS_SPECIFIC_DOCUMENT_REJECTED = 'UPDATE_ESS_SPECIFIC_DOCUMENT_REJECTED'

export const updateDocument = ({ logo, signature, structure, type, id }) => dispatch => dispatch({
  type: UPDATE_ESS_SPECIFIC_DOCUMENT,
  meta: { id },
  payload: updateDocumentService({ logo, signature, structure, type, id })
}).catch(console.error)

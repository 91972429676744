
import { combineReducers } from 'redux'
import latestDocuments from './latest-documents'
import collection from './collection'
import revenue from './revenue'

export default combineReducers({
  latestDocuments,
  collection,
  revenue
})

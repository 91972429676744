import { settingGet } from '../redux/util'
import { DEFAULT_LANGUAGE } from '../../constants/internationalization'
import * as locales from '../../locales'
import { updateLanguage } from '../../actions/internationalization'

export default async store => {
  const language = await settingGet('language') || DEFAULT_LANGUAGE
  let locale = language
  if (!(locale in locales)) {
    locale = locale.slice(0, 2)
    if (!(locale in locales)) {
      locale = DEFAULT_LANGUAGE
    }
  }
  store.dispatch(updateLanguage(await locales[locale]()))
}

import React, { useState, useEffect } from 'react'
import styles from './LoginFirst.module.css'
import propTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import Card from '@material-ui/core/Card'
import Surface from 'react-surfaces'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import { useTheme } from '@material-ui/core/styles'
import AwesomeButton from '../AwesomeButton/AwesomeButton'
import RawLink from '../RawLink/RawLink'
import Button from '@material-ui/core/Button'
import SettingsIcon from '@material-ui/icons/Settings'

const LoginFirst = props => {
  const { messages, to } = props
  const theme = useTheme()
  const [hover, setHover] = useState(false)
  const hoverEnable = setHover.bind(null, true)
  const hoverDisable = setHover.bind(null, false)

  console.log(theme)

  return (
    <div className={styles.LoginFirst}>
      <div className={styles.surface}>
        <Surface
          varient={2} // defaults to 1, possible values: (1-6)
          color1={hover ? theme.palette.secondary.main : theme.palette.primary.main} // defaults to #e52421
          color2={hover ? theme.palette.primary.main : theme.palette.secondary.main} // defaults to #0000ff
          transitionDuration={'2s'} // optional
          transitionDelay={'0s'} // optional
          transitionTimingFunction={'ease'} // optional
        />
      </div>
      <Card onMouseEnter={hoverEnable} onMouseLeave={hoverDisable} onTouchStart={hoverEnable} onTouchEnd={hoverDisable} className={styles.card}>
        <CardContent className={styles.cardContent}>
          <Typography color="inherit" gutterBottom variant="h4" component="h2">
            {get(messages, 'memberLogin.message')}
          </Typography>
          <Typography color="inherit" variant="body2" component="p">
            {get(messages, 'loginFirst.message')}
          </Typography>

          <RawLink to={to}>
            <AwesomeButton>{get(messages, 'login.message', 'Login')}</AwesomeButton>
          </RawLink>

          <br />

          <RawLink to={'/settings'}>
            <Button startIcon={<SettingsIcon/>}>{get(messages, 'openSettings.message', 'Open settings')}</Button>
          </RawLink>
        </CardContent>
      </Card>
    </div>
  )
}

LoginFirst.propTypes = {
  to: propTypes.string.isRequired,
  messages: propTypes.object.isRequired
}

LoginFirst.defaultProps = {

}

export default LoginFirst

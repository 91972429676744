import store from '../../store'
import Settings from '../settings/Settings'

export const shouldFetch = (resource, loading, errors) =>
  resource === null &&
  loading === false &&
  (
    errors === null ||
    (Array.isArray(errors) && errors.length === 0)
  )

export const settingGet = (setting = '') => {
  return Settings.getSetting(setting)
}

export const observe = (store, listener) => {
  listener()
  store.subscribe(listener)
}

export const getMessages = () => {
  return store.getState().internationalization.messages
}

import React from 'react'
import { Route, Link } from 'react-router-dom'
import { SIGN_IN } from '../constants/routes'
import get from 'lodash/get'
import asyncComponent from '../hocs/AsyncComponent'
import FullScreenLoader from '../components/FullScreenLoader/FullScreenLoader'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import LoginFirst from './LoginFirst'
import withInternationalization from '../hocs/Internationalization'

const stateSelector = createSelector(
  state => state.auth,
  (auth, settings) => ({ props: { ...auth } })
)

const enhance = compose(
  withInternationalization(),
  connect(stateSelector)
)

export default enhance(({ messages, component: C, props: cProps, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated
        ? <C {...props} {...cProps} />
        : cProps.isAuthenticating
          ? <FullScreenLoader text={get(messages, 'authenticating.message')} />
          : <div>
            <LoginFirst to={`${SIGN_IN}?redirect=${props.location.pathname}${props.location.search}`} />
          </div>
    }
  />
)

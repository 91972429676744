import get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'

const withInternationalization = ({ a } = {}) => Component => {
  const stateSelector = createSelector(
    state => get(state, 'internationalization'),
    (internationalization) => ({ ...internationalization })
  )

  const enhance = compose(
    connect(stateSelector)
  )

  const ComponentWithInternationalization = props => {
    return (<Component {...props} />)
  }

  return enhance(ComponentWithInternationalization)
}

export default withInternationalization

import Button from '@material-ui/core/Button';
import get from 'lodash/get';
import propTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import withInternationalization from '../../hocs/Internationalization';
import styles from './FullScreenError.module.css';

const FullScreenError = props => {
  const { messages } = props
  console.log(props.children)
  return (
    <div className={styles.filler}>
      <h2 className={styles.text}>{props.children || '' + (props.text || get(messages, 'unknownError.message'))}</h2>
      <br />
      <Button onClick={() => props.history.goBack()} variant="contained" color="secondary">{get(messages, 'back.message', 'Back')}</Button>
    </div>
  )
}

FullScreenError.propTypes = {
  children: propTypes.node,
  history: propTypes.object,
  text: propTypes.string,
  messages: propTypes.object
}

FullScreenError.defaultProps = {

}

const enhance = compose(
  withInternationalization(),
  withRouter
)

export default enhance(FullScreenError)

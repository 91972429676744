import { applyMiddleware, createStore, compose } from 'redux'
// import { reactReduxFirebase } from 'react-redux-firebase'
// import { reduxFirestore } from 'redux-firestore' // <- needed if using firestore
// import firebase from './lib/firebase';

import logger from 'redux-logger'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import reducer from './reducers'

const middleware = applyMiddleware(promise, thunk, logger)

const enhance = compose(
  middleware
)

export default createStore(reducer, enhance)


import pickBy from 'lodash/pickBy'
import querystring from 'querystring'

export function isExternalURL (url) {
  const httpProtocol = url.startsWith('http://') || url.startsWith('https://')
  return httpProtocol && new URL(url).host !== (window.location.host)
}

export const validURL = value => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
}

export const querystringParse = q => {
  if (typeof q !== 'string') {
    return {}
  }

  if (q[0] === '?') q = q.slice(1)

  return querystring.parse(q)
}

export const querystringStringify = q => {
  if (typeof q !== 'object') {
    return ''
  }

  q = pickBy(q, x => x !== undefined)

  return querystring.stringify(q)
}
